import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments-evaluateur/environment';
import { Comite, EnumPhaseComite, SearchComitesFilter } from '@shared-ui';
import { SharedFunction } from '@shared-evaluateur/utils/sharedFunction';
import { Observable } from 'rxjs';

@Injectable()
export class ComiteService {
  constructor(private httpClient: HttpClient, private sharedFunction: SharedFunction) {}

  getComites(filters?: SearchComitesFilter): Observable<HttpResponse<Comite[]>> {
    const _filter = filters ?? {};
    return this.httpClient.post<Comite[]>(
      (this.sharedFunction.isSiteAdmin() ? environment.apiAdminUrl : environment.apiUrl) + 'comites/filtres',
      JSON.stringify(_filter),
      { observe: 'response' }
    );
  }

  getComiteById(id: string): Observable<HttpResponse<Comite>> {
    return this.httpClient.get<Comite>(environment.apiAdminUrl + 'comites/' + id, { observe: 'response' });
  }

  saveComite(comite: Comite, isEdit?: boolean): Observable<HttpResponse<Comite>> {
    if (isEdit) {
      return this.httpClient.put<Comite>(environment.apiAdminUrl + 'comites', JSON.stringify(comite), { observe: 'response' });
    }
    return this.httpClient.post<Comite>(environment.apiAdminUrl + 'comites', JSON.stringify(comite), { observe: 'response' });
  }

  closeComites(comiteIds: string[]): Observable<HttpResponse<Comite[]>> {
    return this.httpClient.patch<Comite[]>(environment.apiAdminUrl + 'comites/cloture', comiteIds, { observe: 'response' });
  }

  autoAffectInstructeur(idComites: string[]): Observable<HttpResponse<any>> {
    return this.httpClient.patch<Comite>(environment.apiAdminUrl + 'comites/instructeurs/affectation', JSON.stringify(idComites), {
      observe: 'response',
    });
  }

  autoDesaffectInstructeur(idComites: string[]): Observable<HttpResponse<any>> {
    return this.httpClient.patch<Comite>(environment.apiAdminUrl + 'comites/instructeurs/desaffectation', JSON.stringify(idComites), {
      observe: 'response',
    });
  }

  downloadExportEvaluation(comiteId: string): Observable<HttpResponse<string>> {
    return this.httpClient.get(environment.apiAdminUrl + 'comites/' + comiteId + '/evaluation-export', {
      responseType: 'text',
      observe: 'response',
    });
  }

  downloadExportAudition(comiteId: string): Observable<HttpResponse<string>> {
    return this.httpClient.get(environment.apiAdminUrl + 'comites/' + comiteId + '/audition-export', {
      responseType: 'text',
      observe: 'response',
    });
  }

  downloadExportSelection(comiteId: string): Observable<HttpResponse<string>> {
    return this.httpClient.get(environment.apiAdminUrl + 'comites/' + comiteId + '/selection-export', {
      responseType: 'text',
      observe: 'response',
    });
  }

  assignProjetsToComite(comiteId: string, projetsIds: string[], phase: EnumPhaseComite): Observable<HttpResponse<Comite>> {
    return this.httpClient.patch<Comite>(
      environment.apiAdminUrl + 'comites/' + comiteId + '/projets',
      {
        projetsIds,
        phase,
      },
      {
        observe: 'response',
      }
    );
  }
}
